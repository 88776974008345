import { motion } from 'motion/react'
import React from 'react'
import * as rdd from 'react-device-detect'
import { ReactComponent as StarRatingsSvg } from './images/star-ratings.svg'

const MOTION_DELAY_INITIAL = 0.2
const MOTION_DURATION = 0.75

export default function PartnershipsStarRatingsSvg() {
  const isSafari = rdd.isSafari

  // animations aren't reliable in safari
  if (isSafari) {
    return <StarRatingsSvg />
  }

  const pathMotionViewport = {
    once: true,
  }
  const pathMotionProps = {
    viewport: pathMotionViewport,
    initial: {
      opacity: 0,
      //x: isSafari ? 0 : -20,
      x: -20,
    },
    whileInView: {
      opacity: 1,
      x: 0,
    },
  }

  return (
    <svg
      viewBox="0 0 500 425"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: '100%',
        maxWidth: 500,
      }}>
      <motion.path
        d="M58.5725 209.252L47.3225 218.959L50.75 233.477C50.9391 234.265 50.8904 235.091 50.6101 235.852C50.3297 236.612 49.8303 237.272 49.1748 237.749C48.5194 238.226 47.7374 238.498 46.9276 238.53C46.1178 238.563 45.3165 238.354 44.625 237.932L32 230.162L19.3675 237.932C18.6761 238.352 17.8758 238.558 17.0673 238.525C16.2589 238.491 15.4786 238.219 14.8245 237.742C14.1704 237.266 13.6719 236.607 13.3918 235.848C13.1116 235.089 13.0623 234.264 13.25 233.477L16.69 218.959L5.44 209.252C4.82824 208.723 4.38581 208.026 4.16795 207.247C3.9501 206.468 3.96647 205.643 4.21503 204.873C4.4636 204.104 4.93333 203.425 5.56557 202.921C6.19781 202.417 6.96454 202.11 7.77 202.039L22.52 200.849L28.21 187.079C28.518 186.329 29.0422 185.687 29.7159 185.235C30.3897 184.783 31.1825 184.542 31.9937 184.542C32.8049 184.542 33.5978 184.783 34.2716 185.235C34.9453 185.687 35.4695 186.329 35.7775 187.079L41.465 200.849L56.215 202.039C57.0221 202.108 57.791 202.412 58.4256 202.916C59.0601 203.419 59.532 204.099 59.7821 204.869C60.0322 205.639 60.0494 206.466 59.8316 207.246C59.6137 208.026 59.1705 208.725 58.5575 209.254L58.5725 209.252Z"
        fill="#FFCC1A"
        viewport={pathMotionViewport}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.75 }}
      />
      <motion.path
        d="M167.572 209.252L156.322 218.959L159.75 233.477C159.939 234.265 159.89 235.091 159.61 235.852C159.33 236.612 158.83 237.272 158.175 237.749C157.519 238.226 156.737 238.498 155.928 238.53C155.118 238.563 154.317 238.354 153.625 237.932L141 230.162L128.367 237.932C127.676 238.352 126.876 238.558 126.067 238.525C125.259 238.491 124.479 238.219 123.825 237.742C123.17 237.266 122.672 236.607 122.392 235.848C122.112 235.089 122.062 234.264 122.25 233.477L125.69 218.959L114.44 209.252C113.828 208.723 113.386 208.026 113.168 207.247C112.95 206.468 112.966 205.643 113.215 204.873C113.464 204.104 113.933 203.425 114.566 202.921C115.198 202.417 115.965 202.11 116.77 202.039L131.52 200.849L137.21 187.079C137.518 186.329 138.042 185.687 138.716 185.235C139.39 184.783 140.183 184.542 140.994 184.542C141.805 184.542 142.598 184.783 143.272 185.235C143.945 185.687 144.47 186.329 144.777 187.079L150.465 200.849L165.215 202.039C166.022 202.108 166.791 202.412 167.426 202.916C168.06 203.419 168.532 204.099 168.782 204.869C169.032 205.639 169.049 206.466 168.832 207.246C168.614 208.026 168.171 208.725 167.557 209.254L167.572 209.252Z"
        fill="#FFCC1A"
        {...pathMotionProps}
        transition={{
          delay: MOTION_DELAY_INITIAL,
          duration: MOTION_DURATION,
        }}
      />
      <motion.path
        d="M276.572 209.252L265.322 218.959L268.75 233.477C268.939 234.265 268.89 235.091 268.61 235.852C268.33 236.612 267.83 237.272 267.175 237.749C266.519 238.226 265.737 238.498 264.928 238.53C264.118 238.563 263.317 238.354 262.625 237.932L250 230.162L237.367 237.932C236.676 238.352 235.876 238.558 235.067 238.525C234.259 238.491 233.479 238.219 232.825 237.742C232.17 237.266 231.672 236.607 231.392 235.848C231.112 235.089 231.062 234.264 231.25 233.477L234.69 218.959L223.44 209.252C222.828 208.723 222.386 208.026 222.168 207.247C221.95 206.468 221.966 205.643 222.215 204.873C222.464 204.104 222.933 203.425 223.566 202.921C224.198 202.417 224.965 202.11 225.77 202.039L240.52 200.849L246.21 187.079C246.518 186.329 247.042 185.687 247.716 185.235C248.39 184.783 249.183 184.542 249.994 184.542C250.805 184.542 251.598 184.783 252.272 185.235C252.945 185.687 253.47 186.329 253.777 187.079L259.465 200.849L274.215 202.039C275.022 202.108 275.791 202.412 276.426 202.916C277.06 203.419 277.532 204.099 277.782 204.869C278.032 205.639 278.049 206.466 277.832 207.246C277.614 208.026 277.171 208.725 276.557 209.254L276.572 209.252Z"
        fill="#FFCC1A"
        {...pathMotionProps}
        transition={{
          delay: MOTION_DELAY_INITIAL * 2,
          duration: MOTION_DURATION,
        }}
      />
      <motion.path
        d="M385.572 209.252L374.322 218.959L377.75 233.477C377.939 234.265 377.89 235.091 377.61 235.852C377.33 236.612 376.83 237.272 376.175 237.749C375.519 238.226 374.737 238.498 373.928 238.53C373.118 238.563 372.317 238.354 371.625 237.932L359 230.162L346.367 237.932C345.676 238.352 344.876 238.558 344.067 238.525C343.259 238.491 342.479 238.219 341.825 237.742C341.17 237.266 340.672 236.607 340.392 235.848C340.112 235.089 340.062 234.264 340.25 233.477L343.69 218.959L332.44 209.252C331.828 208.723 331.386 208.026 331.168 207.247C330.95 206.468 330.966 205.643 331.215 204.873C331.464 204.104 331.933 203.425 332.566 202.921C333.198 202.417 333.965 202.11 334.77 202.039L349.52 200.849L355.21 187.079C355.518 186.329 356.042 185.687 356.716 185.235C357.39 184.783 358.183 184.542 358.994 184.542C359.805 184.542 360.598 184.783 361.272 185.235C361.945 185.687 362.47 186.329 362.777 187.079L368.465 200.849L383.215 202.039C384.022 202.108 384.791 202.412 385.426 202.916C386.06 203.419 386.532 204.099 386.782 204.869C387.032 205.639 387.049 206.466 386.832 207.246C386.614 208.026 386.171 208.725 385.557 209.254L385.572 209.252Z"
        fill="#FFCC1A"
        {...pathMotionProps}
        transition={{
          delay: MOTION_DELAY_INITIAL * 3,
          duration: MOTION_DURATION,
        }}
      />
      <motion.path
        d="M494.573 209.252L483.323 218.959L486.75 233.477C486.939 234.265 486.89 235.091 486.61 235.852C486.33 236.612 485.83 237.272 485.175 237.749C484.519 238.226 483.737 238.498 482.928 238.53C482.118 238.563 481.317 238.354 480.625 237.932L468 230.162L455.368 237.932C454.676 238.352 453.876 238.558 453.067 238.525C452.259 238.491 451.479 238.219 450.825 237.742C450.17 237.266 449.672 236.607 449.392 235.848C449.112 235.089 449.062 234.264 449.25 233.477L452.69 218.959L441.44 209.252C440.828 208.723 440.386 208.026 440.168 207.247C439.95 206.468 439.967 205.643 440.215 204.873C440.464 204.104 440.933 203.425 441.566 202.921C442.198 202.417 442.965 202.11 443.77 202.039L458.52 200.849L464.21 187.079C464.518 186.329 465.042 185.687 465.716 185.235C466.39 184.783 467.183 184.542 467.994 184.542C468.805 184.542 469.598 184.783 470.272 185.235C470.945 185.687 471.47 186.329 471.778 187.079L477.465 200.849L492.215 202.039C493.022 202.108 493.791 202.412 494.426 202.916C495.06 203.419 495.532 204.099 495.782 204.869C496.032 205.639 496.049 206.466 495.832 207.246C495.614 208.026 495.171 208.725 494.558 209.254L494.573 209.252Z"
        fill="#FFCC1A"
        {...pathMotionProps}
        transition={{
          delay: MOTION_DELAY_INITIAL * 4,
          duration: MOTION_DURATION,
        }}
      />
    </svg>
  )
}
