import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { motion } from 'motion/react'
import React from 'react'
import Media from 'react-media'
import TextLoop from 'react-text-loop'
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemTitle,
} from 'components/Accordion'
import Box from 'components/Box'
import Button, { ButtonProps } from 'components/Button'
import Card from 'components/Card'
import Carousel2 from 'components/Carousel2'
import Container, { type ContainerProps } from 'components/Container'
import Head from 'components/Head'
import Heading from 'components/Heading'
import Layout from 'components/Layout'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Rows from 'components/Rows'
import Section, { type SectionProps } from 'components/Section'
import Text from 'components/Text'
import TextNumber from 'components/TextNumber'
import Title from 'components/Title'
import PartnershipsClinicalOutcomesSvg from 'components/pages/partnerships/PartnershipsClinicalOutcomesSvg'
import PartnershipsMemberExperience from 'components/pages/partnerships/PartnershipsMemberExperience'
import PartnershipsStarRatingsSvg from 'components/pages/partnerships/PartnershipsStarRatingsSvg'
import paths from 'constants/paths'
import useEventPageVisited from 'hooks/use-event-page-visited'
import constants from 'styles/constants.module.scss'
import styles from './Partnerships.module.scss'
import { ReactComponent as BlogSvg } from './images/blog.svg'
import { ReactComponent as ChecklistSvg } from './images/checklist.svg'
import { ReactComponent as HandHeartSvg } from './images/hand-heart.svg'
import { ReactComponent as HeartSvg } from './images/heart.svg'
import { ReactComponent as LogoSvg } from './images/logo.svg'
import { ReactComponent as PeopleSvg } from './images/people.svg'
import { ReactComponent as SpeechBubblesSvg } from './images/speech-bubbles.svg'

function PartnershipsButton(props: { children: React.ReactNode }) {
  return (
    <motion.div
      {...props}
      viewport={{
        amount: 'all',
        once: true,
      }}
      initial={{
        opacity: 0,
        y: 24,
      }}
      whileInView={{
        opacity: 1,
        transition: { delay: 0.3 },
        y: 0,
      }}
    />
  )
}

export default function Partnerships() {
  useEventPageVisited('Health plans')
  const blogData = useStaticQuery(graphql`
    query {
      allMdx(
        filter: {
          frontmatter: {
            #category: { eq: "health-plans" }
            draft: { ne: true }
            partnerships: { eq: true }
            template: { eq: "blog" }
          }
          # did not work across enviornments
          #id: {
          #  in: [
          #    "9a2846cb-2b1c-5b72-aecc-da43ba1a8318"
          #    "63cbd42c-e71c-5765-ba35-594bec7f940d"
          #    "2af5a7db-1d6d-54b2-a388-b8fc50ca3b60"
          #  ]
          #}
        }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        nodes {
          id
          fields {
            slug
          }
          frontmatter {
            #category
            image {
              childImageSharp {
                fluid(maxWidth: 414, maxHeight: 212) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            partnerships
            title
          }
        }
      }
    }
  `)

  const features = [
    {
      title: 'Personalized to maximize engagement',
      copy: 'More than 95% of older adults have a chronic condition, and 80% have 2 or more; Bold ensures that every member has access to appropriate exercise that can deliver real-world benefit.',
      id: 'personalized',
      image: (
        <StaticImage
          src="./images/features/personalized@2x.png"
          alt=""
          height={492}
          options={{
            quality: 100,
          }}
        />
      ),
    },
    {
      title: 'Science-based content library',
      copy: 'Bold’s exercise and educational content is created by a team of experts in healthy aging, including clinicians, physical therapists, and trainers.',
      id: 'library',
      image: (
        <StaticImage
          src="./images/features/library@2x.png"
          alt=""
          height={492}
          options={{
            quality: 100,
          }}
        />
      ),
    },
    {
      title: 'Tailored nudges',
      copy: 'Sophisticated behavioral science is embedded in every aspect of the Bold experience to keep members engaged month after month.',
      id: 'reminders',
      image: (
        <StaticImage
          src="./images/features/reminders@2x.png"
          alt=""
          height={492}
          options={{
            quality: 100,
          }}
        />
      ),
    },
    {
      title: 'Rich reporting and insights',
      copy: 'Bold screens every member at onboarding for HOS-related risks and assesses members across dozens of progress metrics to help plans achieve their clinical and quality goals.',
      id: 'reporting',
      image: (
        <StaticImage
          src="./images/features/reporting@2x.png"
          alt=""
          height={492}
          options={{
            quality: 100,
          }}
        />
      ),
    },
  ]

  const [featureActive, setFeatureActive] = React.useState(features[0].id)
  const featureActiveItem = features.find((item) => item.id === featureActive) || features[0]

  const benefitWords = [
    {
      copy: 'Star Ratings',
      background: constants.YELLOW_100,
      color: constants.YELLOW_500,
    },
    {
      copy: 'clinical',
      background: constants.BLUE_100,
      color: constants.BLUE_500,
    },
    {
      copy: 'member experience',
      background: constants.PURPLE_100,
      color: constants.PURPLE_500,
    },
    {
      copy: 'full',
      background: constants.GREEN_100,
      color: constants.GREEN_500,
    },
  ]

  const benefits = [
    {
      title: 'Star Ratings',
      copy: 'Address all 5 HOS-derived measures with a single, easy-to-manage solution.',
      visual: <PartnershipsStarRatingsSvg />,
    },
    {
      title: 'Clinical outcomes',
      copy: 'In third-party analyses, Bold has been shown to drive a 50% reduction in fall-related hospitalizations.',
      visual: <PartnershipsClinicalOutcomesSvg />,
    },
    {
      title: 'Member experience',
      copy: '4-in-5 members say they are more likely to keep their health plan because of their Bold membership.',
      visual: <PartnershipsMemberExperience />,
    },
  ]

  const stats = [
    {
      value: 40,
      valueAppend: '%',
      caption: 'of members had no exercise history prior to Bold',
      background: '#b3c6ff',
      color: 'blue',
    },
    {
      value: 61,
      valueAppend: '%',
      caption: 'report clinically significant pain reduction',
      background: '#809fff',
      color: 'blue',
    },
    {
      value: 85,
      valueAppend: '%',
      caption: 'report improved emotional health',
      background: '#4d79ff',
      color: 'white',
    },
    {
      value: 81,
      caption: 'member NPS',
      background: '#1a53ff',
      color: 'white',
    },
  ]

  const testimonials = [
    {
      copy: 'Bold has been life changing for me physically, cognitively, and emotionally. The trainers are outstanding in their ability to connect with an aging population from varied backgrounds.',
      author: 'Sarah M., 67',
      color: constants.PURPLE,
    },
    {
      copy: 'When I started Bold, two and a half years ago, I did two 15-minute workouts a week. Took me a year to progress to three 25-minute workouts each week. Now I can do five 45-minute live classes! The process works!',
      author: 'Greg L., 73',
      color: constants.PINK,
    },
    {
      copy: 'My family has commented on how my outlook on my long-term goals for my health and fitness has grown exponentially since I joined Bold.',
      author: 'Linda W., 81',
      color: constants.ORANGE,
    },
    {
      copy: 'I have seen such an improvement in my strength, flexibility and cardio endurance since doing Bold’s live classes.',
      author: 'Richard A., 68',
      color: constants.BLUE,
    },
  ]

  const faqs = [
    {
      q: 'Who does Bold partner with?',
      a: 'Bold partners with Medicare Advantage plans, provider groups, and health systems to improve older adults’ quality of life, reduce care costs, and drive clinical outcomes. Get in touch with our team to learn more about how Bold can support your goals.',
    },
    {
      q: 'How many people does Bold currently serve?',
      a: 'More than 10 million Americans nationwide have access to one of our products through our partnerships.',
    },
    {
      q: 'Is Bold clinically validated?',
      a: 'Yes, Bold’s outcomes have been verified in third-party analyses by plan partners to show reduction in falls, hospitalizations, and total cost of care.',
    },
  ]

  const buttonProps: Partial<ButtonProps> = {
    size: 'xlarge',
    to: paths.REQUEST_DEMO,
  }

  const containerProps: Partial<ContainerProps> = {
    size: 'xxxlarge',
  }

  const sectionProps: Partial<SectionProps> = {
    size: 'xlarge',
  }

  const groupMotionProps = {
    viewport: {
      //amount: 'all',
      amount: 'some',
      once: true,
    },
    initial: 'hidden',
    whileInView: 'visible',
    variants: {
      visible: {
        transition: {
          staggerChildren: 0.4,
        },
      },
    },
  }

  const groupItemMotionProps = {
    viewport: {
      amount: 'all',
      once: true,
    },
    variants: {
      hidden: {
        opacity: 0,
        y: 40,
      },
      visible: {
        opacity: 1,
        y: 0,
      },
    },
  }

  return (
    <Layout
      headerProps={{
        color: 'purple',
      }}
      className={classNames('Partnerships', styles.this)}
      data-test-id="partnerships">
      <Head
        bodyAttributes={{ class: 'body---grey' }}
        title="Health plans and Bold"
        titleOnly
        description="Our health plan partnerships save money and improve lives. Bold’s programs achieve clinical outcomes most relevant to Medicare plans."
      />
      <Text color="white" element="header" className={styles.hero}>
        <div className={styles['hero--copy']}>
          <Container {...containerProps}>
            <Container align="left" flush size="medium">
              <Row size="xsmall">
                <Text
                  element="h1"
                  heading
                  size="large"
                  weight="bold"
                  className={styles['hero--title']}>
                  Turning barriers to exercise into reasons to engage
                </Text>
              </Row>
              <Text element="p" size="large" className={styles['hero--subtext']}>
                Bold helps members move more and engage with their health more often, improving Star
                Ratings, driving clinical outcomes, and increasing member satisfaction.
              </Text>
              <PartnershipsButton>
                <Button {...buttonProps} color="white" className={styles['hero--button']}>
                  Request demo
                </Button>
              </PartnershipsButton>
            </Container>
          </Container>
        </div>
        <div className={styles['hero--image']}>
          <motion.div
            viewport={{
              once: true,
            }}
            initial={{
              opacity: 0,
              y: 120,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}>
            <Media
              queries={{
                default: `(min-width: ${constants.GT_DESKTOP})`,
                small: `(min-width: ${constants.GT_TABLET_SMALL}) and (max-width: ${constants.DESKTOP})`,
                xsmall: `(max-width: ${constants.TABLET_SMALL})`,
              }}
              defaultMatches={{ default: true }}>
              {(matches) => (
                <>
                  {matches.default && (
                    <StaticImage
                      src="./images/devices@2x.png"
                      alt="Bold screenshot on mobile and in browser"
                      placeholder="none"
                      width={740}
                    />
                  )}
                  {matches.small && (
                    <StaticImage
                      src="./images/devices@2x.png"
                      alt="Bold screenshot on mobile and in browser"
                      placeholder="none"
                      width={740}
                    />
                  )}
                  {matches.xsmall && (
                    <Container size="xxxlarge">
                      <StaticImage
                        src="./images/device@2x.png"
                        alt="Bold mobile screenshot"
                        placeholder="none"
                        width={356}
                      />
                    </Container>
                  )}
                </>
              )}
            </Media>
          </motion.div>
        </div>
      </Text>

      <Section {...sectionProps}>
        <Container {...containerProps}>
          <Row size="large">
            <Title small="The Bold difference" smallIcon={LogoSvg} align="left" flush>
              <div className={styles.words}>
                Unlock the{' '}
                <TextLoop interval={2750}>
                  {benefitWords.map((item) => (
                    <Text
                      key={item.copy}
                      wrap={false}
                      style={{
                        background: item.background,
                        color: item.color,
                      }}
                      className={styles['words--item']}>
                      {item.copy}
                    </Text>
                  ))}
                </TextLoop>{' '}
                value of exercise for payers
              </div>
            </Title>
          </Row>
          <Row size="large">
            <Media
              queries={{
                default: `(min-width: ${constants.GT_MOBILE})`,
                small: `(max-width: ${constants.MOBILE})`,
              }}
              defaultMatches={{ default: true }}>
              {(matches) => (
                <>
                  {matches.default && (
                    <div className={styles['carousel-wrap']}>
                      <Carousel2
                        options={{
                          align: 'start',
                          //loop: true
                        }}
                        slides={benefits.map((item) => (
                          <Box
                            align="center"
                            color="grey"
                            element="figure"
                            key={item.title}
                            className={styles.benefit}>
                            <Row size="large">
                              <div className={styles['benefit--visual']}>{item.visual}</div>
                            </Row>
                            <Text
                              element="figcaption"
                              size="large"
                              className={styles['benefit--copy']}>
                              <Heading level={3} levelStyle={4}>
                                {item.title}
                              </Heading>
                              <Text element="p" flush>
                                {item.copy}
                              </Text>
                            </Text>
                          </Box>
                        ))}
                      />
                    </div>
                  )}
                  {matches.small && (
                    <Rows size="xmedium">
                      {benefits.map((item) => (
                        <Box
                          align="center"
                          color="grey"
                          element="figure"
                          key={item.title}
                          className={styles.benefit}>
                          <Row>
                            <div className={styles['benefit--visual']}>{item.visual}</div>
                          </Row>
                          <Text
                            element="figcaption"
                            size="large"
                            className={styles['benefit--copy']}>
                            <Heading level={3} levelStyle={4}>
                              {item.title}
                            </Heading>
                            <Text element="p" flush>
                              {item.copy}
                            </Text>
                          </Text>
                        </Box>
                      ))}
                    </Rows>
                  )}
                </>
              )}
            </Media>
          </Row>
          <Container flush size="xlarge">
            <Text align="center">
              <Row>
                <Text
                  color="black"
                  element="p"
                  flush
                  heading
                  size="xxlarge"
                  weight="bold"
                  className={styles['connect-title']}>
                  Bold is the only exercise solution for Medicare Advantage Organizations that
                  delivers across Star Ratings, clinical outcomes, and member experience.
                </Text>
              </Row>
              <Button {...buttonProps}>Connect with us</Button>
            </Text>
          </Container>
        </Container>
      </Section>

      <Section {...sectionProps} divided>
        <Container {...containerProps}>
          <Container align="left" flush size="medium">
            <Title
              small="Features"
              smallIcon={ChecklistSvg}
              subtext="Built from the ground up for older adults, Bold is an intuitive platform that helps each member start and sustain lifelong healthy habits."
              align="left">
              A modern approach to exercise your members will love
            </Title>
          </Container>
          <Media
            queries={{
              default: `(min-width: ${constants.GT_TABLET_SMALL})`,
              small: `(max-width: ${constants.TABLET_SMALL})`,
            }}
            defaultMatches={{ default: true }}>
            {(matches) => (
              <>
                {matches.default && (
                  <MediaObject
                    figure={
                      <>
                        <Row>
                          <Accordion
                            preExpanded={[`${features[0].id}`]}
                            className={styles['product-accordion']}
                            onChange={(activeUuids) => setFeatureActive(activeUuids[0])}>
                            {features.map((item) => (
                              <AccordionItem key={item.id} uuid={item.id}>
                                <AccordionItemTitle chevron={false}>
                                  <Text element="h3" heading size="xlarge" weight="bold">
                                    {item.title}
                                  </Text>
                                </AccordionItemTitle>
                                <AccordionItemBody>
                                  <Text element="p" size="large">
                                    {item.copy}
                                  </Text>
                                </AccordionItemBody>
                              </AccordionItem>
                            ))}
                          </Accordion>
                        </Row>
                        <PartnershipsButton>
                          <Button {...buttonProps}>Learn more</Button>
                        </PartnershipsButton>
                      </>
                    }
                    center
                    size="mediumx"
                    className={styles.product}>
                    <Box
                      align="center"
                      color="grey"
                      element="figure"
                      flush
                      className={styles['product--box']}>
                      {React.cloneElement(featureActiveItem.image, {
                        className: styles['product-accordion--screenshot'],
                      })}
                    </Box>
                  </MediaObject>
                )}
                {matches.small && (
                  <div>
                    <Row size="smallx">
                      {features.map((item) => (
                        <section key={item.title} className={styles['product---small']}>
                          <Heading level={3} levelStyle={4}>
                            {item.title}
                          </Heading>
                          <Row size="small">
                            <Box
                              align="center"
                              color="grey"
                              element="figure"
                              flush
                              className={styles['product--box']}>
                              {item.image}
                            </Box>
                          </Row>
                          <Text element="p" size="large">
                            {item.copy}
                          </Text>
                        </section>
                      ))}
                    </Row>
                    <PartnershipsButton>
                      <Button {...buttonProps}>Learn more</Button>
                    </PartnershipsButton>
                  </div>
                )}
              </>
            )}
          </Media>
        </Container>
      </Section>

      <Section {...sectionProps} align="center" className={styles.results}>
        <Container {...containerProps}>
          <Container flush size="medium">
            <Title
              small="Results"
              smallColor="blue"
              smallIcon={PeopleSvg}
              subtext="Bold is the modern exercise solution for Medicare Advantage Organizations, delivering the value plans should expect across clinical, quality, and member experience.">
              Our outcomes, your impact
            </Title>
          </Container>
          <Row>
            <Container {...containerProps} flush>
              <motion.div {...groupMotionProps}>
                <div className={styles.stats}>
                  {stats.map((item) => (
                    <motion.div
                      {...groupItemMotionProps}
                      key={item.caption}
                      style={{ background: item.background }}
                      className={styles.stat}>
                      <Text
                        align="center"
                        color={item.color === 'blue' ? 'blue500' : item.color}
                        element="figure">
                        <Row size="xxxsmall">
                          <TextNumber
                            value={item.value}
                            append={item.valueAppend}
                            size="xxxlarge"
                            weight="bold"
                            wrap={false}
                          />
                        </Row>
                        <Text copy element="figcaption">
                          {item.caption}
                        </Text>
                      </Text>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            </Container>
          </Row>
          <PartnershipsButton>
            <Button {...buttonProps} color="blue">
              Meet with our experts
            </Button>
          </PartnershipsButton>
        </Container>
      </Section>

      <Section {...sectionProps}>
        <Container {...containerProps}>
          <Container align="left" flush size="medium">
            <Title
              small="Testimonials"
              smallIcon={HeartSvg}
              subtext="Improving mental and physical health through compassionate support."
              align="left">
              What members say about Bold
            </Title>
          </Container>
          <Row>
            <div className={styles['testimonials-wrap']}>
              {/* <motion.div {...groupMotionProps}> */}
              <div className={styles.testimonials}>
                {/* <motion.div {...groupItemMotionProps} key={item.author}> */}
                {testimonials.map((item) => (
                  <Text
                    element="figure"
                    key={item.author}
                    style={{ borderColor: item.color }}
                    className={styles.testimonial}>
                    <Row size="xmedium">
                      <Text
                        color="black"
                        element="blockquote"
                        size="xlarge"
                        weight="bold"
                        className={styles['testimonial--copy']}>
                        “{item.copy}”
                      </Text>
                    </Row>
                    <Text element="figcaption" size="large">
                      — {item.author}
                    </Text>
                  </Text>
                ))}
                {/* </motion.div> */}
              </div>
              {/* </motion.div> */}
            </div>
          </Row>
          <PartnershipsButton>
            <Button {...buttonProps}>Bring Bold to your members</Button>
          </PartnershipsButton>
        </Container>
      </Section>

      <Section {...sectionProps} divided>
        <Container {...containerProps}>
          <Text align="center" element="div">
            <Title small="Working with us" smallIcon={SpeechBubblesSvg}>
              Frequently asked questions
            </Title>
            <Row>
              <Container flush size="medium">
                <motion.div {...groupMotionProps}>
                  <Rows>
                    {faqs.map((item) => (
                      <motion.div {...groupItemMotionProps} key={item.q}>
                        <Box color="grey" size="small">
                          <Heading level={3} levelStyle={4}>
                            {item.q}
                          </Heading>
                          <Text element="p" flush size="large">
                            {item.a}
                          </Text>
                        </Box>
                      </motion.div>
                    ))}
                  </Rows>
                </motion.div>
              </Container>
            </Row>
            <PartnershipsButton>
              <Button {...buttonProps}>Get in touch</Button>
            </PartnershipsButton>
          </Text>
        </Container>
      </Section>

      <Section {...sectionProps} divided>
        <Container {...containerProps}>
          <Container align="left" flush size="medium">
            <Title
              small="Blog"
              smallIcon={BlogSvg}
              subtext="Get the latest insights from our team of experts on member engagement, Stars, and more."
              align="left">
              More from Bold
            </Title>
          </Container>
          <motion.div {...groupMotionProps}>
            <div className={styles.blogs}>
              {blogData?.allMdx?.nodes?.map((item) => (
                <motion.div {...groupItemMotionProps} key={item.id}>
                  <Card
                    image={item.frontmatter.image}
                    title={item.frontmatter.title}
                    to={item.fields.slug}
                  />
                </motion.div>
              ))}
            </div>
          </motion.div>
        </Container>
      </Section>

      <Section {...sectionProps} divided>
        <Title
          small="Join us"
          smallIcon={HandHeartSvg}
          actions={
            <PartnershipsButton>
              <Button {...buttonProps}>Partner with Bold</Button>
            </PartnershipsButton>
          }>
          Ready to get started?
        </Title>
      </Section>
    </Layout>
  )
}
