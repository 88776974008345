import { EmblaOptionsType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import { AnimatePresence, motion } from 'motion/react'
import React from 'react'
//import * as rdd from 'react-device-detect'
import './Carousel2.scss'
import {
  NextButton,
  PreviousButton,
  usePreviousNextButtons,
} from './components/Carousel2ArrowButtons'

type PropertyType = {
  slides: React.ReactNode[]
  options?: EmblaOptionsType
}

export default function EmblaCarousel(props: PropertyType) {
  const { slides, options } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options)
  const { previousButtonDisabled, nextButtonDisabled, onPreviousButtonClick, onNextButtonClick } =
    usePreviousNextButtons(emblaApi)

  // animations aren't reliable in safari
  //const isSafari = rdd.isSafari

  //React.useEffect(() => {
  //  if (!emblaApi || !isSafari) return
  //
  //  // hacky fix for bug where safari won't animate until you interact with the carousel
  //  emblaApi.scrollTo(1)
  //  const timeout = setTimeout(() => {
  //    emblaApi.scrollTo(0)
  //  }, 1000)
  //
  //  return () => clearTimeout(timeout)
  //}, [emblaApi, isSafari])

  return (
    <section className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides.map((item, index) => (
            <div className="embla__slide" key={index}>
              {item}
            </div>
          ))}
        </div>
      </div>
      <div className="embla__controls">
        <div className="embla__buttons">
          <AnimatePresence>
            {!previousButtonDisabled && (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <PreviousButton
                  //disabled={previousButtonDisabled}
                  onClick={onPreviousButtonClick}
                />
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {!nextButtonDisabled && (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <NextButton
                  //disabled={nextButtonDisabled}
                  onClick={onNextButtonClick}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </section>
  )
}
