import classNames from 'classnames'
import { animate, motion, useInView, useMotionValue, useTransform } from 'motion/react'
import React from 'react'
import Text, { type TextProps } from 'components/Text'
import styles from './TextNumber.module.scss'

type Props = {
  append?: string
  onSuccess?: () => void
  value: number
} & TextProps

export default function TextNumber({ append, className, onSuccess, value, ...props }: Props) {
  const ref = React.useRef(null)
  const isInView = useInView(ref, {
    amount: 'all',
    once: true,
  })
  const count = useMotionValue(0)
  const rounded = useTransform(() => Math.round(count.get()))

  React.useEffect(() => {
    if (!isInView) return
    //const controls =
    animate(count, value, { duration: 2 }).then(() => {
      if (onSuccess) onSuccess()
    })
    //return () => controls.stop()
  }, [count, isInView, onSuccess, value])

  return (
    <Text {...props} className={classNames('TextNumber', styles.this, className)}>
      <motion.span ref={ref}>{rounded}</motion.span>
      {append}
    </Text>
  )
}
