import { EmblaCarouselType } from 'embla-carousel'
import React, { ComponentPropsWithRef, useCallback, useEffect, useState } from 'react'
import './Carousel2ArrowButtons.scss'
import { ReactComponent as ArrowLeftSvg } from './images/arrow---left.svg'
import { ReactComponent as ArrowRightSvg } from './images/arrow---right.svg'

type UsePreviousNextButtonsType = {
  nextButtonDisabled: boolean
  onNextButtonClick: () => void
  onPreviousButtonClick: () => void
  previousButtonDisabled: boolean
}

export const usePreviousNextButtons = (
  emblaApi: EmblaCarouselType | undefined
): UsePreviousNextButtonsType => {
  const [previousButtonDisabled, setPreviousButtonDisabled] = useState(true)
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)

  const onPreviousButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
  }, [emblaApi])

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
  }, [emblaApi])

  // TODO
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPreviousButtonDisabled(!emblaApi.canScrollPrev())
    setNextButtonDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onSelect])

  return {
    nextButtonDisabled,
    onNextButtonClick,
    onPreviousButtonClick,
    previousButtonDisabled,
  }
}

type PropertyType = ComponentPropsWithRef<'button'>

export function PreviousButton(props: PropertyType) {
  const { children, ...restProps } = props

  return (
    <button className="embla__button embla__button--prev" type="button" {...restProps}>
      {/* <svg className="embla__button__svg" viewBox="0 0 532 532">
        <path
          fill="currentColor"
          d="M355.66 11.354c13.793-13.805 36.208-13.805 50.001 0 13.785 13.804 13.785 36.238 0 50.034L201.22 266l204.442 204.61c13.785 13.805 13.785 36.239 0 50.044-13.793 13.796-36.208 13.796-50.002 0a5994246.277 5994246.277 0 0 0-229.332-229.454 35.065 35.065 0 0 1-10.326-25.126c0-9.2 3.393-18.26 10.326-25.2C172.192 194.973 332.731 34.31 355.66 11.354Z"
        />
      </svg> */}
      <ArrowLeftSvg />
      {children}
    </button>
  )
}

export function NextButton(props: PropertyType) {
  const { children, ...restProps } = props

  return (
    <button className="embla__button embla__button--next" type="button" {...restProps}>
      {/* <svg className="embla__button__svg" viewBox="0 0 532 532">
        <path
          fill="currentColor"
          d="M176.34 520.646c-13.793 13.805-36.208 13.805-50.001 0-13.785-13.804-13.785-36.238 0-50.034L330.78 266 126.34 61.391c-13.785-13.805-13.785-36.239 0-50.044 13.793-13.796 36.208-13.796 50.002 0 22.928 22.947 206.395 206.507 229.332 229.454a35.065 35.065 0 0 1 10.326 25.126c0 9.2-3.393 18.26-10.326 25.2-45.865 45.901-206.404 206.564-229.332 229.52Z"
        />
      </svg> */}
      <ArrowRightSvg />
      {children}
    </button>
  )
}
