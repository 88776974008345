import React from 'react'
import Confetti from 'components/Confetti'
import TextNumber from 'components/TextNumber'
import styles from './PartnershipsMemberExperience.module.scss'

export default function PartnershipsMemberExperience() {
  const [shouldConfettiRun, setShouldConfettiRun] = React.useState(false)

  return (
    <>
      <Confetti
        colors={['#e4daf5']}
        gravity={0.06}
        numberOfPieces={1500}
        recycle={false}
        run={shouldConfettiRun}
      />
      <TextNumber
        value={80}
        append="%"
        color="black"
        weight="bold"
        wrap={false}
        onSuccess={() => setShouldConfettiRun(true)}
        className={styles.number}
      />
    </>
  )
}
